<script>
  import { theme } from "../store/theme.js";
  import RemoveLinkModal from "./RemoveLinkModal.svelte";

  export let update;

  let themeValue;
  theme.subscribe(value => {
    themeValue = value;
  });

  let modalOpen = false;
</script>

<style>
  button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 16rem;
    background: none;
    border: none;
    cursor: pointer;
  }
 .icon{
    font-size: 5rem;
    line-height: 1;
    width: 5rem;
    height: 5rem;
    padding: 2.5rem;
    display: block;
  }

  h2 {
    font-weight: 400;
    position: absolute;
    top: 10rem;
    transition: opacity 0.2s ease 0.2s, color 0.2s ease-out;
    color: inherit;
  }

  button:hover h2 {
    opacity: 0;
  }

  .description {
    margin-top: 0;
    width: 18rem;
  }

  .logo-wrapper {
    width: 10rem;
    height: 10rem;
  }

  .bkg {
    width: 10rem;
    height: 10rem;
    border: 1px solid rgba(225, 227, 232, 0.18);
    box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
    background-color: #fff;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
    position: absolute;
  }
  .dark .bkg {
    background-color: #1d1d1d;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);
  }
  button:focus {
    outline: none;
  }
  button:hover .bkg, button:focus .bkg {
    width: 20rem;
    height: 20rem;
    border-radius: 0.4rem;
    z-index: 10;
    transform: translateY(-3rem);
  }
</style>

<div>
  <button
    class={themeValue}
    on:click={()=> {modalOpen = !modalOpen}}
  >
    <div class="bkg">
      <div class="logo-wrapper">
        <span class="icon">-</span>
      </div>
      <p class="description">Fjern en custom link</p>
    </div>
    <h2>Slett link</h2>
  </button>
  {#if modalOpen}
    <RemoveLinkModal close={()=> {modalOpen = false; update();}} />
  {/if}
</div>

