<script>
  export let close;

  let title = "";
  let description = "";
  let href = "";
  let logo = "";

  const saveLink = () => {
    const links = JSON.parse(localStorage.getItem("customLinks")) || [];
    links.push({title, href, description, logo});
    localStorage.setItem("customLinks", JSON.stringify(links));
    close();
  }
</script>

<style>
  .bkg {
    background: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items:  center;
    z-index: 12;
  }

  .modal {
    position: fixed;
    z-index: 11;
    width: 40rem;
    max-width:90vw;
    margin: 0 auto;

    border: 1px solid rgba(225, 227, 232, 0.18);
    box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
    background-color: #fff;
    color: #121212;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 4rem 4.8rem 6rem 4.8rem;
  }

  p {
    margin-bottom: 32px;
  }
  
  label {
    margin-bottom: 1.2rem;
  }

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-size: 2rem;
    padding: 1rem;
    line-height: 0.8;
    cursor: pointer;
  }

  .closeBtn:hover, .closeBtn:focus{
      background: #eee; 
  }
</style>

<div class="bkg" on:click={close}>
  <div
    class="modal"
    on:click={e => e.stopPropagation()}
  >
  <button class="closeBtn" on:click={close} aria-label="Close modal">x</button>
    <h2>Ny link</h2>
    <p>
      Disclaimer: Egendefinerte linker lagres i nettleseren (localstorage). 
      Iterbart stiller ingen garanti for at ikke nettleseren sletter dem.
    </p>
    <form on:submit={saveLink}>
      <label>
        Navn <br/>
        <input type="text" bind:value={title} />
      </label>
      <label>
        URL<br/>
        <input type="text" bind:value={href} />
      </label>
      <label>
        Beskrivelse<br/>
        <input type="text" bind:value={description} />
      </label>
        <label>
        Ikon - en bokstav eller emoji<br/>
        <input type="text" bind:value={logo} />
      </label>
      <button type="submit">Lagre</button>
    </form>
  </div>
</div>
