import { writable } from "svelte/store";

const getInitialTheme = () => {
  let initialTheme = localStorage.getItem("theme");
  if (initialTheme) {
    return initialTheme;
  }
  return "light";
};

export const theme = writable(getInitialTheme());

export const setTheme = value => {
  theme.set(value);
  localStorage.setItem("theme", value);
};
