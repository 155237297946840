<script>
  export let close;

  let links = JSON.parse(localStorage.getItem("customLinks")) || [];

  const removeLink = (linkTitle) => {
    links = links.filter(val => val.title !== linkTitle);
    localStorage.setItem("customLinks", JSON.stringify(links));
  }

</script>

<style>
  .bkg {
    background: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items:  center;
    z-index: 12;
  }
  
  h2 {
    margin-bottom: 32px;
  }

  .modal {
    position: fixed;
    z-index: 11;
    width: 40rem;
    max-width:90vw;
    margin: 0 auto;

    border: 1px solid rgba(225, 227, 232, 0.18);
    box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
    background-color: #fff;
    color: #121212;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 4rem 4.8rem 6rem 4.8rem;
  }

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-size: 2rem;
    padding: 1rem;
    line-height: 0.8;
    cursor: pointer;
  }

  .closeBtn:hover, .closeBtn:focus{
      background: #eee; 
  }

  .linkRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }

  .href {
    font-style: italic;
    font-size: 0.8em;
    white-space: nowrap;
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="bkg" on:click={close}>
  <div
    class="modal"
    on:click={e => e.stopPropagation()}
  >
    <button class="closeBtn" on:click={close} aria-label="Close modal">x</button>
    <h2>Fjern link</h2>
    {#each links as link}
      <div class="linkRow">
        <div>
          {link.title}
           <div class="href">{link.href}</div> 
        </div>
        <button on:click={()=>removeLink(link.title)}>Slett</button>
      </div>
    {/each}
  </div>
</div>
