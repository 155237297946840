<script>
  const bartPeople = [
    { name: "/img/bartpeople/iter-bart-person-1.svg", winnerChance: 0.4 },
    { name: "/img/bartpeople/iter-bart-person-2.svg", winnerChance: 0.4 },
    { name: "/img/bartpeople/iter-bart-person-3.svg", winnerChance: 0.1 },
    { name: "/img/bartpeople/iter-bart-person-4.svg", winnerChance: 0.3 },
    { name: "/img/bartpeople/iter-bart-person-5.svg", winnerChance: 0.3 },
    { name: "/img/bartpeople/iter-bart-person-6.svg", winnerChance: 0.2 },
    { name: "/img/bartpeople/iter-bart-person-7.svg", winnerChance: 1 },
    { name: "/img/iterbart.png", winnerChance: 1 }
  ];

  function getBartPerson() {
    let luckyWinner = null;
    while (!luckyWinner) {
      let selected = bartPeople[Math.floor(Math.random() * bartPeople.length)];
      let randomNumber = Math.random();

      if (randomNumber < selected.winnerChance) {
        luckyWinner = selected.name;
      }
    }

    return luckyWinner;
  }
</script>

<style>
  img {
    width: 200px;
    margin-top: 1.6rem;
  }
</style>

<img src={getBartPerson()} alt="" />
