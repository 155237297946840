<script>
  import { theme, setTheme } from "../store/theme.js";

  let themeValue;
  theme.subscribe(value => {
    themeValue = value;
  });

  let toggle = themeValue === "light" ? false : true;

  $: if (toggle) {
    setTheme("dark");
  }

  $: if (!toggle) {
    setTheme("light");
  }
</script>

<style>
  .toggle {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
</style>

<label class="toggle">
  <input type="checkbox" bind:checked={toggle} />
  <span>Dark mode</span>
</label>
