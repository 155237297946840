<script>
  import Link from "./Link.svelte";
  import AddLink from "./AddLink.svelte";
  import RemoveLink from "./RemoveLink.svelte";
  import { links } from "../../public/data/links.json";

  let customLinks = JSON.parse(localStorage.getItem("customLinks")) || [];
  const update = () => {
    customLinks = JSON.parse(localStorage.getItem("customLinks")) || [];
  };
</script>

<div class="LinkContainer">
  {#each links as link}
    <Link {link} />
  {/each}
  {#each customLinks as link}
    <Link {link} tracking={false} />
  {/each}
  <AddLink {update} />
  <RemoveLink {update} />
</div>

<style>
  .LinkContainer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    justify-content: left;
    max-width: 120rem;
    margin: 0 auto;
    padding: 4rem 4vw;
    justify-items: center;
  }

  @media (min-width: 300px) {
    .LinkContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 430px) {
    .LinkContainer {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (min-width: 600px) {
    .LinkContainer {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media (min-width: 980px) {
    .LinkContainer {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
</style>
