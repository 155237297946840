<script>
  import { theme } from "./store/theme.js";

  import Logo from "./components/Logo.svelte";
  import LinkContainer from "./components/LinkContainer.svelte";
  import ThemeSelector from "./components/ThemeSelector.svelte";

  let themeValue;
  theme.subscribe(value => {
    themeValue = value;
  });
</script>

<style>
  main {
    text-align: center;
    min-height: 100vh;
  }
  .ligth {
    background-color: white;
    color: #333;
  }
  .dark {
    background-color: #121212;
    color: white;
  }
  * {
    transition: color 0.2s ease-out, background-color 0.2s ease;
  }
</style>

<main class={themeValue}>
  <Logo />
  <p>Alle lenkene du trengte, og ikke visste du trengte, i arbeidsdagen din!</p>
  <LinkContainer />
  <ThemeSelector />
</main>
